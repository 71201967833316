<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Пользователи')"
    :paramsGetData="paramsGetData"
    :hasLang="false"
    :prefixEdit="`/user`"
    :prefixShow="`/user`"
    :linkCreate="`/user/create`"
    :keyTable="'userId'"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_CODE } from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "UserPage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_USER,
      apiDeleteName: API_METHODS.DELETE_USER
    };
  },
  computed: {
    paramsGetData() {
      return { UserId: this.$route.params.UserIdId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.Admin
    }
  },
  methods: {
    deleteParams(id) {
      return { id:id };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE, true);
        tempdataNow = tempdataNow.map(item => {
          return {
           ...item, isActive: item.isActive?this.$t('Да'):this.$t("Нет"), validDate :  formatData(item.validDate) 
          };
        });
        return tempdataNow;
      }

      return [];
    }
  }
};
</script>
